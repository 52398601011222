import {useMutation, useQuery} from 'react-query';
import {
    ApiError,
    Author,
    CampaignDetails,
    CampaignOverview,
    ParticipationFilter,
} from '../types';
import {CampaignService} from '../types/services/CampaignService';
import {
    AUTHOR_GET,
    CAMPAIGN_GET,
    CAMPAIGN_GET_LIKE,
    CAMPAIGN_GET_OVERVIEW,
    CAMPAIGN_GET_PACK,
    CAMPAIGN_GET_PARTICIPATION,
    CAMPAIGN_GET_URL,
} from './keys';

export function useGetCampaign() {
    return useQuery(CAMPAIGN_GET, CampaignService.getCampaign);
}

export function useGetCampaignDetail(
    enabled: boolean,
    url: string,
    initialData: CampaignDetails | null | undefined = null
) {
    let options = {};
    if (initialData) {
        options = {
            enabled: enabled,
            initialData: initialData,
        };
    } else {
        options = {
            enabled: enabled,
        };
    }
    return useQuery(
        [CAMPAIGN_GET_URL, url],
        () => CampaignService.getCampaignDetail(url),
        options
    );
}

export function useGetCampaignOverview(enabled: boolean, url: string) {
    return useQuery(
        [CAMPAIGN_GET_OVERVIEW, url],
        () => CampaignService.getCampaignOverview(url),
        {
            enabled: enabled,
        }
    );
}

export function useGetCampaignPack(enabled: boolean, url: string) {
    return useQuery([CAMPAIGN_GET_PACK, url], () => CampaignService.getCampaignPack(url), {
        enabled: enabled,
    });
}

export function useGetAuthorByUrl(
    enabled: boolean,
    url: string,
    initialData: Author | null | undefined = null
) {
    let options = {};
    if (initialData) {
        options = {
            enabled: enabled,
            initialData: initialData,
        };
    } else {
        options = {
            enabled: enabled,
        };
    }

    return useQuery<Author, ApiError, Author>(
        AUTHOR_GET + ':' + url,
        () => CampaignService.getAuthorByUrl(url),
        options
    );
}

export function useGetAuthorCampaigns(enabled: boolean, authorId: string) {
    return useQuery<CampaignOverview[], ApiError, CampaignOverview[]>(
        AUTHOR_GET + ':' + authorId,
        () => CampaignService.getAuthorCampaigns(authorId),
        {
            enabled: enabled,
        }
    );
}

export function useGetCampaignLike(enabled: boolean) {
    return useQuery(CAMPAIGN_GET_LIKE, CampaignService.getCampaignLike, {
        enabled: enabled,
    });
}

export function useLikeCampaign() {
    return useMutation<number, ApiError, string>((campaignUrl: string) =>
        CampaignService.likeCampaign(campaignUrl)
    );
}

export function useGetCampaignParticipation(
    campaignId: string,
    page: number,
    size: number,
    enabled: boolean
) {
    return useQuery(
        CAMPAIGN_GET_PARTICIPATION + ':' + campaignId + ':' + page,
        () =>
            CampaignService.getCampaignParticipation(
                {
                    campaign_id: campaignId,
                    order: 'DESC',
                    comment_only: true,
                } as ParticipationFilter,
                page,
                size
            ),
        {
            enabled: enabled,
        }
    );
}

export async function fetchCampaignParticipation(
    campaignId: string,
    order: string,
    commentOnly: boolean,
    page: number,
    size: number
) {
    return await CampaignService.getCampaignParticipation(
        {
            campaign_id: campaignId,
            order: order,
            comment_only: commentOnly,
        } as ParticipationFilter,
        page,
        size
    );
}
